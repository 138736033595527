import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Sentry initialization for error tracking and performance monitoring
Sentry.init({
  dsn: 'https://your-sentry-dsn-url',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0, // Adjust this for performance monitoring
  environment: process.env.NODE_ENV, // Adds environment info (production/development)
  release: 'your-app@1.0.0', // Add versioning
});

// Lazy loading components
const Landing = lazy(() => import('./Landing'));
const InsuranceTypePage = lazy(() => import('./InsuranceTypePage'));
//const ACACensusForm = lazy(() => import('./aca/ACACensusForm'));

const NotFound = () => <div>404 Page Not Found</div>;

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Landing />
                  <Navigation next="/insurance-type" />
                </>
              }
            />
            <Route
              path="/insurance-type"
              element={
                <>
                  <InsuranceTypePage />
                  <Navigation prev="/" next="/acaCensus" />
                </>
              }
            />
            <Route
              path="/acaCensus"
              element={
                <>
                  <ACACensusForm />
                  <Navigation prev="/insurance-type" />
                </>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

