
import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavigationButtons({ nextRoute, prevRoute }) {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-between mt-4">
      {prevRoute && (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => navigate(prevRoute)}
        >
          Previous
        </button>
      )}
      {nextRoute && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate(nextRoute)}
        >
          Next
        </button>
      )}
    </div>
  );
}

export default NavigationButtons;
